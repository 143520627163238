/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import { Layout, Newsletter } from 'layouts'
import { SEO, InstagramGallery } from 'components'
import { SEOTitles } from '../../config/seo'

const Gallery = () => {
  return (
    <Layout>
      <SEO
        title={SEOTitles.gallery.title}
        description={SEOTitles.gallery.description}
        pathname="gallery"
      />

      <InstagramGallery />
      <Newsletter />
    </Layout>
  )
}

export default Gallery
